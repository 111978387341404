import styled from 'styled-components';

// Define a styled anchor component using styled-components
const StyledAnchor = styled.a`
  margin: 0 3px; // Set margin of 0 on top and bottom, 3px on left and right
  font-size: 1.5em; // Set font size to 0.75em
  font-weight: 900; // Set font weight to 900 (bold)
  color: #fff; // Set text color to white (#fff)
  text-decoration: none; // Remove text decoration (underline)
  padding: 0 10px; // Set padding of 0 on top and bottom, 10px on left and right
  font-family: 'Dancing Script', cursive; // Update font family to 'Dancing Script, cursive'


  &:hover {
    color: #00FF00; // Change text color to green (#00FF00) on hover
    text-decoration: underline; // Add underline text decoration on hover
    background: rgba(0, 0, 0, 0.5); // Set background color to rgba(0, 0, 0, 0.5) on hover
    border-radius: 8px; // Set border radius to 8px on hover
  }
`;

// Define an object containing styles for the navigation component
const styles = {
  navigation: {
    width: '100%', // Set width to 100%
    height: '50px', // Set height to 50px
    borderBottom: '1px solid #333', // Add a 1px solid black border at the bottom
    textAlign: 'center', // Set text alignment to center
    backgroundColor: '#000', // Set background color to black (#000)
    paddingTop: '5px', // Set padding top to 5px
  },
};

// Define a functional component called Navigation
const Navigation = () => {
  return (
    <nav style={styles.navigation}>
      <StyledAnchor href="#about" title="About">About</StyledAnchor>
      <StyledAnchor href="#skills" title="Skills">Skills</StyledAnchor>
      <StyledAnchor href="#projects" title="Projects">Projects</StyledAnchor>
      <StyledAnchor href="#contact" title="Contact">Contact</StyledAnchor>
    </nav>
  );
};

export default Navigation; // Export the Navigation component as the default export
