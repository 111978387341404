import React, { useState } from 'react';
import mySlide1 from '../assets/images/MagazineFinal.png';
import mySlide2 from '../assets/images/ConspiracyMag1.png';
import mySlide3 from '../assets/images/ailogodesign.png';
import mySlide4 from '../assets/images/webwireframe.png';
import mySlide5 from '../assets/images/JavaScriptDemo.png';
import mySlide6 from '../assets/images/MediaScreenDemo.png';
import mySlide7 from '../assets/images/jacks.png';
import mySlide8 from '../assets/images/CollegeDatabaset.png';
import mySlide9 from '../assets/images/ToySite.png';
import mySlide10 from '../assets/images/SpaceGame.png';
import mySlide11 from '../assets/images/BigCats.png';
import mySlide12 from '../assets/images/NewsApp.png';
import mySlide13 from '../assets/images/ArtisticPrint.png';
import mySlide14 from '../assets/images/movieturtle.png';
import mySlide19 from '../assets/images/display.png';

// Define an object containing styles for the Projects component
const styles = {
  container: {
    display: 'flex', // Set display to flex
    flexDirection: 'column', // Set flex direction to column
    alignItems: 'center', // Center align items horizontally
    justifyContent: 'center', // Center align items vertically
    minHeight: '100vh', // Set minimum height to 100 viewport height
  },
  slider: {
    position: 'relative', // Set position to relative
    width: '100%', // Set width to 100%
    maxWidth: '1000px', // Set maximum width to 1000px
    margin: '0 auto', // Center align horizontally by setting left and right margins to auto
    overflow: 'hidden', // Hide any overflowing content
  },
  sliderImage: {
    width: '60%', // Set width to 60% of the parent container
    height: 'auto', // Automatically calculate height based on width to maintain aspect ratio
    margin: '0 auto', // Center align horizontally by setting left and right margins to auto
  },
  projectsHeading: {
    fontSize: '3.5rem', // Set font size to 3.5rem
    fontWeight: 'bold', // Set font weight to bold
    color: '#000', // Set text color to black (#000)
    fontFamily: 'Dancing Script, cursive', // Set font family to 'Dancing Script' with fallback to cursive
    textShadow: '2px 2px 5px rgba(0,0,0,0.5)', // Apply a text shadow with offset and blur
  },
  description: {
    padding: '0 10%', // Add left and right padding of 10%
  },
};

const Projects = () => {
  const [currentSlide, setCurrentSlide] = useState(0); // Define state for the current slide and a function to update it
  const slides = [
    mySlide1, // Slide 1 image
    mySlide2, // Slide 2 image
    mySlide3, // Slide 3 image
    mySlide4, // Slide 4 image
    mySlide5, // Slide 5 image
    mySlide6, // Slide 6 image
    mySlide7, // Slide 7 image
    mySlide8, // Slide 8 image
    mySlide9, // Slide 9 image
    mySlide10, // Slide 10 image
    mySlide11, // Slide 11 image
    mySlide12, // Slide 12 image
    mySlide13, // Slide 13 image
    mySlide14, // Slide 14 movie buff
    mySlide19, // Slide 19 image
  ];
  const descriptions = [
    <p>
      <span style={{ fontWeight: 'bold', color: '#15b3e7' }}>
        1. Glamping Canada Magazine Cover:
      </span>
      <br />
      <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
        Experience the epitome of luxury camping in the breathtaking Canadian wilderness with this captivating mock magazine cover. Using Adobe Photoshop, I designed a visually stunning front cover that embodies the essence of glamping in Canada. The cover features a beautifully styled image of a couple enjoying a romantic getaway on a pristine beach. The typography elegantly showcases the magazine's title, while the left side of the cover displays articles with page numbers, offering a glimpse into the exciting content within.
        <br></br>
        <br></br>
      </span>
    </p>,
    <p>
      <span style={{ fontWeight: 'bold', color: '#15b3e7' }}>
        2. Conspiracy Chronicles Magazine Cover:
      </span>
      <br />
      <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
        Uncover the secrets that lie beneath the surface with this intriguing mock magazine cover. Using Adobe Photoshop, I crafted a captivating front cover that delves into the world of conspiracy theories. The cover showcases a meticulously styled collage of images, featuring prominent figures such as Elon Musk, Joe Biden, and Donald Trump, along with enigmatic symbols and mysterious references. On the right side, you'll find a collection of thought-provoking articles, each accompanied by their corresponding page numbers, offering a glimpse into the hidden narratives that await within the pages of this mind-bending publication.
      </span>
      <br></br>
      <br></br>
    </p>,

    <p>
      <span style={{ fontWeight: 'bold', color: '#15b3e7' }}>
        3. Adobe Illustrator Logo Design:
      </span>
      <br />
      <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
        I employed Adobe Illustrator to craft a minimalist yet impactful logo for my hand therapist. The logo design features a clean and understated aesthetic, utilizing both a black and white version for versatility, as well as four variations in a soothing Sage color palette. To capture the essence of the hand therapy practice, I incorporated a symbolic representation of a hand within the logo concept. Through meticulous attention to detail and the powerful design tools of Adobe Illustrator, I was able to create a visually compelling and meaningful logo that effectively communicates the essence of my hand therapy services.
      </span>
      <br></br>
      <br></br>
    </p>,

    <p>
      <span style={{ fontWeight: 'bold', color: '#15b3e7' }}>4. Adobe Illustrator Wire Frame:</span>
      <br />
      <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
      Crafted with precision and creativity using Adobe Illustrator, my wireframe model serves as a blueprint for the seamless integration of design and functionality in digital interfaces. This meticulously constructed model encapsulates the essence of user experience, offering a visual roadmap for the intuitive navigation and interaction within web applications, mobile apps, or websites.
      </span>
      <br></br>
      <br></br>
    </p>,

    <p>
      <span style={{ fontWeight: 'bold', color: '#15b3e7' }}>5. JavaScriptDemo:</span>
      <br />
      <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
        I have created a JavaScript application using Microsoft Visual Studio (shown above).
        <br />
        <ol>
        <li>Defined a function that takes a single string parameter representing a word.
        </li>
        <li>Determined the rules for forming the plural of the word. For example, the plural of most English nouns is formed by adding an "s" to the end of the word, but there are exceptions like "man" becoming "men" and "child" becoming "children".
        </li>
        <li> Wrote a series of conditional statements that apply the pluralization rules to the input word. Each condition should check whether the input word matches a specific pattern and then apply the corresponding rule if it does.
        </li>
        <li>Return the pluralized word as the output of the function.
        </li>
        </ol>
        <br></br>
      </span>
    </p>,

    <p>
      <span style={{ fontWeight: 'bold', color: '#15b3e7' }}>6. Java FX media Demo:</span>
      <br />
      <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
      I've crafted a JavaFX application utilizing the Media, MediaPlayer, and MediaView classes, as well as event handling mechanisms, to deliver a captivating and interactive audiovisual experience. Through seamless integration of multimedia elements, users are immersed in an engaging environment where they can explore dynamic audio and video content. Whether it's enjoying music, watching videos, or interacting with multimedia presentations, this application offers a seamless and intuitive experience, thanks to its sophisticated use of JavaFX features. Experience the power of multimedia brought to life through this innovative JavaFX application.
        <br></br>
        <br></br>
      </span>
    </p>,
    
    
    <p>
    <span style={{ fontWeight: 'bold', color: '#15b3e7' }}>7. Jacks Bar and Grill:</span>
    <br />
    {/* Launch site button */}
    <button onClick={() => window.open('https://filipturcic.com/jacks/', '_blank')} style={{ fontWeight: 'bold', color: '#15b3e7', border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }}>
      Launch Site
    </button>
    <br />
    <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
      For my Web Fundamentals project, I created a dynamic and responsive website called 'Jacks Bar and Grill,' with a captivating Halloween theme. Leveraging the power of HTML, CSS, and JavaScript, I developed an interactive website using Visual Studio. The website features an engaging opening page adorned with vibrant costumes and Halloween-themed visuals. It showcases the bar's drink specials, food specials, and offers an immersive streaming media experience with Halloween-themed content. The website is designed to be responsive, ensuring a seamless browsing experience across various devices and screen sizes.
      <br></br>
      <br></br>
    </span>
    
  </p>,

    <p>
    <span style={{ fontWeight: 'bold', color: '#15b3e7' }}>8. College Database:</span>
    <br />
    <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
 <p>     
As an introduction to the intricate world of data modeling, I've meticulously crafted a robust database management system tailored to streamline student and residence management within the dynamic environment of a college or university. This comprehensive system, showcased above, embodies a thoughtful approach to organizing and managing crucial student and residence data, ensuring seamless operations within educational institutions.
</p>
<br></br>
<p>
Leveraging the power of Microsoft SQL Server, I've expertly translated this meticulous design into a functional database, offering a reliable and efficient platform for managing diverse student records, residence allocations, and associated administrative tasks. Through strategic implementation and meticulous attention to detail, this database management system stands as a testament to the fusion of theoretical concepts and practical application in the realm of data modeling.</p>
      <br></br>
      <br></br> 
    </span>
    </p>,

<p>
<span style={{ fontWeight: 'bold', color: '#15b3e7' }}>9. Toy Store:</span>
<br />
<span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
The Toy Store application is a dynamic web platform developed using Eclipse IDE, Tomcat Server, and MySQL Workbench, leveraging the power of Java Server Pages (JSP) and Bootstrap framework.
  <br></br>
  This comprehensive shopping cart solution caters to all aspects of online retail management. From product browsing to seamless checkout experiences, it empowers users with essential functionalities for managing products, cart items, orders, and user authentication.

  <br></br>
</span>
</p>,
<p>
<span style={{ fontWeight: 'bold', color: '#15b3e7' }}>10. Space Shooter Game:</span>
<br />
    {/* Launch site button */}
    <button onClick={() => window.open('https://filipturcic.com/space/', '_blank')} style={{ fontWeight: 'bold', color: '#15b3e7', border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }}>
      Launch Site
    </button>
    <br />
<span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
Space Invaders game for Windows  providing an engaging and nostalgic gaming experience, combining simple yet addictive gameplay mechanics with retro-style graphics and sound effects.
<br></br>
<br></br>
</span>

</p>,

<p>
<span style={{ fontWeight: 'bold', color: '#15b3e7' }}>11. Big Cats Themed Site:</span>
<br />
<span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
The website is developed using the Django web framework, written in Django's templating language, which allows for dynamic content rendering and integration with Python-based backend logic. Additionally, HTML and CSS are used to structure and style the webpage content, respectively.

<p>
  <br></br>
"World of Big Cats," serves as a captivating gateway to explore the mesmerizing realm of big cats, specifically the genus Panthera. Through engaging content and immersive visuals, visitors are invited to delve into the extraordinary lives of these magnificent felines, ranging from the regal lions of the African savannah to the elusive snow leopards of the Himalayas.
</p>
<br></br>

</span>
</p>,

<p>
<span style={{ fontWeight: 'bold', color: '#15b3e7' }}>12. News Android App:</span>
<br />
<span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
An Android news  app using APIs providing users with a comprehensive and customizable platform for staying informed, connecting with current events, and exploring a diverse range of news sources and topics.
<br></br><br></br>
</span>
</p>,

<p>
<span style={{ fontWeight: 'bold', color: '#15b3e7' }}>13. Artistic Print Works:</span>
<br />
    {/* Launch site button */}
    <button onClick={() => window.open('https://artisticprintworks.com/', '_blank')} style={{ fontWeight: 'bold', color: '#15b3e7', border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }}>
      Launch Site
    </button>
    <br />
<span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
Artistic Print Works is a PHP application designed to provide users with a delightful shopping experience for a variety of creative products. 
<ol>
  <b>Features include:</b>
  <li>Beautiful T-Shirts, Colorful Decals and Wonderful Coloring Books</li>
  <li>Shopping Cart and Checkout:</li>
  </ol> 
  <br></br>
</span>
</p>,

<p>
<span style={{ fontWeight: 'bold', color: '#15b3e7' }}>14. Movie Buff Store:</span>
<br></br>
<span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
  The Movie Buff app is an online platform designed to cater to movie enthusiasts who prefer the convenience of remote shopping experiences. It allows users to browse through a catalog of movies, search for specific titles, and access detailed information about each film, including its title, director, description, release year, rating, price, and cover image.
  <p>
  <b>Features of the Movie Store app include:</b>
  </p><br></br>
  <ul> <li> <b>User Authentication and Management:</b> Users can register an account, log in securely, and manage their profiles, including viewing and editing personal information.</li>

<li><b>Movie Catalog:</b> The app offers a comprehensive catalog of movies searchable by keywords. Users can explore movie details and browse through a variety of options.</li>

<li> <b>Shopping Cart and Checkout:</b> Users can add movies to their shopping cart, review cart contents, and proceed to checkout. They can review shipping details and confirm orders, with options for cash-on-delivery payment.
</li>
<li><b>Order Management:</b> Users can view their order history, track order status, and access detailed order information. Admins have the capability to manage orders, including updating shipping details and order statuses.
</li>
</ul>
<br></br>
</span>
</p>,
    <p>
      <span style={{ fontWeight: 'bold', color: '#15b3e7' }}> Be the Next to Join Our Portfolio:</span>
      <br />
      <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>

        <br />
        <span style={{ fontWeight: 'bold' }}>Are you ready to showcase your business to the world?</span>

        <br />
        We invite you to be part of our growing portfolio of successful projects. Take advantage of this opportunity to have your business featured prominently among other remarkable brands.
        <br />
        <span style={{ fontWeight: 'bold' }}></span>Contact us today to discuss how you can be the next business featured in our portfolio.
      </span>
      <br />
      <span style={{ fontWeight: 'bold' }}>We can't wait to hear about your unique story and help you make a lasting impression.</span>
    </p>,

  
  ];
  const totalSlides = slides.length;

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  return (
    <section id="projects" style={styles.container}>
      <h1 style={styles.projectsHeading}>My Projects</h1>
  
      {/* Slider */}
      <div className="slider" style={styles.slider}>
        {/* Image */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={slides[currentSlide]}
            alt={`Slide ${currentSlide}`}
            style={styles.sliderImage}
          />
        </div>

        {/* Navigation buttons */}

<div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
  <button onClick={prevSlide} style={{ ...styles.navButton, padding: '10px', marginRight: '10px', backgroundColor: '#15b3e7', color: '#fff' }}>
    &lt; {/* Previous button */}
  </button>
  <button onClick={nextSlide} style={{ ...styles.navButton, padding: '10px', backgroundColor: '#15b3e7', color: '#fff' }}>
    &gt; {/* Next button */}
  </button>
</div>

        {/* Description */}
        <div style={styles.description}>
          <p>{descriptions[currentSlide]}</p>
        </div>
      </div>
    </section>
  );
};


export default Projects;
