// Import the hero video and other components
import React from 'react';
import heroVid from '../assets/videos/heroVideo4.mp4';
import Logo from '../components/Logo';
import Social from './Social.Style';

// Define an object containing styles for the hero section
const styles = {
  container: {
    height: '40vh', // sets the height of the container to 50% of the viewport height
    borderBottom: '3px solid #333', // adds a 3px solid black line to the bottom of the container
    borderTop: '3px solid #333', // adds a 3px solid black line to the top of the container
    position: 'relative', // sets the container's position to relative
    overflow: 'hidden', // clips the content of the container if it overflows
    textAlign: 'center', // centers the text horizontally within the container
    paddingTop: '25px', // adds a 25px padding to the top of the container
    display: 'flex', // sets the display property to flex
    alignItems: 'center', // centers the content vertically within the container
    justifyContent: 'center', // centers the content horizontally within the container
  },
  title: {
    fontSize: '2.5rem', // sets the font size of the title to 2.5 rem
    fontWeight: 'bold', // sets the font weight of the title to bold
    color: '#fff', // sets the font color of the title to white
    fontFamily: 'cursive', // sets the font family of the title to cursive
    textShadow: '2px 2px 5px rgba(0,0,0,0.5)', // adds a text shadow to the title
  },

  vid: {
    position: 'absolute', // sets the position of the video to absolute
    zIndex: '-1', // sets the z-index of the video to -1 (so it appears behind other elements)
    top: '50%', // positions the top of the video at 50% of its container
    left: '50%', // positions the left of the video at 50% of its container
    minWidth: '100%', // sets the minimum width of the video to 100%
    minHeight: '100%', // sets the minimum height of the video to 100%
    transform: 'translate(-50%, -50%)', // centers the video within its container using transform
  },
};

// Define an object containing styles for the hero component's position
const heroStyle = {
  position: 'absolute',
  top: '25px',
  right: '25px',
};
// Define a functional component for the hero section
// Display the Logo component
// Display the main title of the hero section
// Use a video as the background of the hero section

const Hero = () => { 
  return (
    <div style={styles.container}>
      <Logo />
      <Social pos={heroStyle} />
      <h1 style={{ 
         fontFamily: 'Dancing Script', 
         fontSize: '3em', 
         color: 'white', 
         textShadow: 'black 0px 0px 5px' 
}}>Turtley awesome tech solutions</h1>

           
      <video style={styles.vid} autoPlay muted loop>
        <source src={heroVid} type="video/mp4" />
      </video>
    </div>
  );
};

// Export the Hero component as the default export of the module
export default Hero;