// Importing the images for the about section from the assets folder
import aboutme from '../assets/images/aboutme.png';
import myPic2 from '../assets/images/aboutme2.png';
import myPic3 from '../assets/images/abouthospitality.png';
import myPic4 from '../assets/images/aboutfabrication.png';




// Object containing style definitions
const styles = {
  container: {
    width: '100%',
    height: 'auto',
    padding: '3%',
    borderBottom: '1px solid #333',
    background: '#fff',
  },
  bioPic: {
    width: '200px',
    borderRadius: '50%',
    border: '5px solid #15b3e7',
    float: 'right',
    margin: '0 0 25px 25px',
  },
  bioPic2: {
    width: '200px',
    borderRadius: '50%',
    border: '5px solid #0E9067',
    float: 'left',
    margin: '0 0 25px 25px',
    
  },
  aboutHeading: {
    fontSize: '3.5rem',
    fontWeight: 'bold',
    color: '#000',
    fontFamily: 'Dancing Script, cursive',
    textShadow: '2px 2px 5px rgba(0,0,0,0.5)',
  },
  paragraph: {
    padding: '0 10%', // Add left and right padding of 10%
  },
};

// Displaying a bio picture with styles <img src={myPic} alt="myPic" style={styles.bioPic} />
const About = () => {
  return (
    <section id="about" style={styles.container}>
        <h1 style={styles.aboutHeading}>About Me</h1>

        
        <img src={aboutme} alt="aboutme" style={styles.bioPic} />
        

       
        <p style={styles.paragraph}>
          I am a Hamilton-based resident in Ontario, Canada, with a clean G driver's license and a background as a skilled laborer, machine operator, and driver. My experience has instilled in me the ability to adapt to changing work environments, work schedules, and locations while being consistently punctual and reliable. Furthermore, I possess exceptional computer skills, allowing me to excel in computer-based tasks and responsibilities.
        </p>
        <br />
        
        <p style={styles.paragraph}>
          Currently, I am enrolled in Enterprise Web & Mobile Developer + Internship at triOS College, which commenced on October 10, 2022, and is expected to be completed on August 02, 2024..
        </p>
        
          <br />
        <h1 style={styles.aboutHeading}>Why You Want To Hire Me</h1>
        <p style={styles.paragraph}>
          With my extensive experience in administrative and computer-related fields, hospitality, sales, and service industry, and steel fabrication, manufacturing, and processing industry, I bring a diverse range of skills to any computer-related job. I am a resident of Hamilton, Ontario, and hold a G license with a clean driver's abstract, allowing me to be flexible with work hours and location. I am punctual, reliable, and have excellent computer skills.
        </p>
        
        <br />
        <img src={myPic2} alt="myPic2" style={{ ...styles.bioPic2, float: 'left' }} />
        <p style={styles.paragraph}>
        As a student in the Enterprise Web & Mobile Developer + Internship program at triOS College, I am gaining up-to-date skills and knowledge to supplement my already extensive experience. My ten years of experience in administrative and computer-related fields have given me the skills to utilize various equipment and applications, provide software training, develop user manuals, marketing materials, and business-related websites, and establish effective business-to-business and business-to-consumer marketing strategies.
        </p>
        <br />
        <img src={myPic3} alt="myPic3" style={styles.bioPic} />
        <p style={styles.paragraph}>
        Moreover, my seven years of experience in the hospitality, sales, and service industry have given me excellent interpersonal skills and the ability to resolve problems with diplomacy and professionalism while ensuring optimal customer satisfaction. I am skilled in tracking and maintaining stock and inventory, cleaning work areas, providing consumer-based information related to computer systems, and maintaining a professional image at all times.
        </p>
        <br />
        <img src={myPic4} alt="myPic4" style={{ ...styles.bioPic2, float: 'left' }} />
        <p style={styles.paragraph}>
        Finally, my nine years of experience in the steel fabrication, manufacturing, and processing industry have given me expert skills in using hand tools, operating machinery, painting, preparing products for shipping, and handling and storing hazardous materials with utmost care and caution. With my diverse range of skills and experience, I am confident that I can bring value and efficiency to any computer-related job.
        </p>
        <br /> <br />

    </section>
  );
};

export default About;