import Skill from './Skill'; // Importing the Skill component

const styles = {
  container: {
    width: '100%', // Set the width of the container to 100%
    height: 'auto', // Set the height of the container to auto
    padding: '50px', // Set the padding of the container
    borderBottom: '1px solid #333', // Set the bottom border of the container
    background: '#ccc', // Set the background color of the container
    textAlign: 'center', // Set the text alignment of the container
  },
  skillsHeading: {
    fontSize: '3.5rem', // Set the font size of the skills heading
    fontWeight: 'bold', // Set the font weight of the skills heading
    color: '#000', // Set the color of the skills heading
    fontFamily: 'Dancing Script, cursive', // Set the font family of the skills heading
    textShadow: '2px 2px 5px rgba(0,0,0,0.5)', // Set the text shadow of the skills heading
  },
  productHeading: {
    color: '#5EB7DB', // Set the color of the product heading
    fontSize: '2.5rem', // Set the font size of the product heading
    fontWeight: 'bold', // Set the font weight of the product heading
    textShadow: '2px 2px 2px rgba(0,0,0,0.5)', // Set the text shadow of the product heading
  },
  paragraph: {
    textAlign: 'left',
    paddingLeft: '10%', // Add left padding of at least 10%
    paddingRight: '10%', // Add right padding of at least 10%
  },
  // Add any additional styles for other elements
};

const Skills = () => {
  return (
    <section id="skills" style={styles.container}>
      <h1 style={styles.skillsHeading}>My Skills</h1>
      <br />
      <h2>Programming and Logic</h2>
      <br />
      
      <p style={styles.paragraph}>
        The Programming and Logic portion of my education has provided me with a solid foundation in programming concepts and techniques, including variables, data types, control structures, functions, object-oriented programming, algorithms, and debugging. These concepts are applicable to many different programming languages and will be used to build a wide range of programs.
      </p>
      <br />

      <h2>Adobe Products</h2>
      <Skill skill="Photoshop" source="devicon-photoshop-line" />
      <Skill skill="illustrator" source="devicon-illustrator-line" />
      <br />
      <p style={styles.paragraph}>
        Proficient in Photoshop and Illustrator. Capabilities include color correction, complex selections, optimization of images for use on the web using Photoshop. Using Illustrator, I am proficient with vector graphics for logos and company branding, as well as creating storyboards or wireframes.
      </p>
      <br />

      <h2>Html, Css and JavaScript</h2>
      <Skill skill="VS code" source="devicon-vscode-plain" />
      <Skill skill="Visual Studio" source="devicon-visualstudio-plain" />
      <Skill skill="React" source="devicon-react-original" />
      <Skill skill="html" source="devicon-html5-plain" />
      <Skill skill="css" source="devicon-css3-plain" />
      <Skill skill="JavaScript" source="devicon-javascript-plain" />
      <br />
      <p style={styles.paragraph}>
        Proficient in VSCode, Visual Studio, and React developing interactive websites using HTML, CSS, and JavaScript. Familiar with HTML5, CSS3, and JavaScript for interactivity.
      </p>
      <br />

      <h2>Java Programming</h2>
      <br />
      <Skill skill="NetBeans" source="devicon-apache-line" />
      <Skill skill="Java" source="devicon-java-plain" />
      <p style={styles.paragraph}>
        Proficient in NetBeans (IDE). Solid understanding of Java programming, including the syntax of the language, object-oriented programming concepts, Java libraries, input/output streams, exception handling, and multithreading.
      </p>
      <br />

      <h2>SQL Programming</h2>
      <Skill skill="SQL Server" source="devicon-microsoftsqlserver-plain" />
      <Skill skill="MySQL" source="devicon-mysql-plain" />
      <Skill skill="C#" source="devicon-csharp-plain" />
      <br />
      <p style={styles.paragraph}>
         Proficient in Microsoft SQL Server Management Studio with the knowledge and skills necessary to work with relational databases using SQL, including creating and modifying databases and tables, querying data, and using advanced SQL techniques like joins, subqueries, and transactions.
      </p>
      <br />

      <h2>Python Programming</h2>
      <Skill skill="Python" source="devicon-python-plain" />
      <br />
      <p style={styles.paragraph}>
        Proficient in Python programming language, including its syntax, data structures, object-oriented programming principles, and commonly used libraries such as NumPy, Pandas, and Matplotlib for data analysis and visualization.
      </p>
      <br />

      <h2>PHP Programming</h2>
      <Skill skill="PHP" source="devicon-php-plain" />
      <br />
      <p style={styles.paragraph}>
        Proficient in PHP programming language, including server-side scripting, web development, and integration with databases such as MySQL. Familiar with PHP frameworks like Laravel and Symfony for building robust web applications.
      </p>
      <br />
    </section>
  );
};

export default Skills;
