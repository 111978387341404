// Import the logo image
import logo from '../assets/images/turtlelogo.png';

// Define an object called styles to hold CSS styles for the Logo component
const styles = {
  logo: {
    position: 'absolute', // Position the logo element absolutely within its container
    width: '110px', // Set the width of the logo element to 110 pixels
    bottom: '5px', // Position the top edge of the logo element 150 pixels from the top edge of its container
    left: '5px', // Position the left edge of the logo element 25 pixels from the left edge of its container
  },
};

// Define the Logo component
const Logo = () => {
  // Return the logo image with the defined styles
  return (
    <img src={logo} style={styles.logo} alt='logo' />
  );
};

// Export the Logo component as the default export
export default Logo;