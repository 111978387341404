
const styles = {
  container: {
    width: '20%', // Set the width of the container
    maxWidth: '150px', // Set the maximum width of the container
    minWidth: '150px', // Set the minimum width of the container
    height: '140px', // Set the height of the container
    padding: '20px 10px', // Set the padding of the container
    border: '1px solid #333', // Set the border of the container
    margin: '10px 1%', // Set the margin of the container
    background: '#fff', // Set the background color of the container
    borderRadius: '16px', // Set the border radius of the container
    boxShadow: '2px 2px 5px #777', // Set the box shadow of the container
    color: '#333', // Set the text color of the container
    display: 'inline-block', // Set the display property of the container
  },
  icon: {
    fontSize: '3rem', // Set the font size of the icon
    color: '#5EB7DB', // Set the color of the icon
  },
  heading: {
    background: '#eee', // Set the background color of the heading
    marginTop: '5px', // Set the top margin of the heading
    padding: '2px 10px', // Set the padding of the heading
    borderRadius: '16px', // Set the border radius of the heading
    fontSize: '.9em', // Set the font size of the heading
    width: '100%', // Set the width of the heading to 100%
    // Add any additional styling properties for the heading
  },
  // Add any additional styles for other elements
};

  
  const Skill = ({source, skill}) => {
    return (
      <div style={styles.container}>
        <i style={styles.icon} class={source}></i>
        <h3 style={styles.heading}>{skill} </h3>
      </div>
    );
  };
  
  export default Skill;