// Import the Social component from the Social.Style file
import Social from './Social.Style';

// Define an object containing styles for the footer
const styles = {
    container: {
      position: 'relative',
      width: '100%',
      height: '150px',
      padding: '25px 50px',
      textAlign: 'center',
      borderBottom: '1px solid #333',
      marginBottom: '50px',
      background: '#333',
      fontSize: '.67em',
      color: '#fff',
      paddingTop: '25px',
    },
    green: {
        color: '#0f0',
    },
    blue: {
      color: '#60E1FD',
  },
  };

  // Define an object containing styles for the footer's position
  const footerStyle = { 
    position: 'absolute',
    bottom: '10px',
    right: '10px',
  };
  // Define a functional component for the footer
  const Footer = () => {
    return (

      // Use the styles defined earlier for the footer
      // Use the Social component, passing in the footerStyle object for positioning
      <footer style={styles.container}>
        <Social pos={footerStyle} />
        <h1>{'\u00A9'} Filip<span style={styles.blue}>Turcic</span> .com </h1>
        <h3>Slow & Steady  Coding...<span style={styles.green}> Wins The Race...</span></h3>
      </footer>
    );
  };
  
  export default Footer;