import React from 'react';
import myPic from '../assets/images/contactme.png';

// define the styles for the various components
const styles = {
  // This object defines styles for the container element that wraps the entire contact section
  container: {
    width: '100%',
    height: '900px',
    padding: '25px 50px',
    borderBottom: '1px solid #333',
    background: '#CCCCCC',
  },

  // This object defines styles for the profile image
  image: {
    display: 'block',
    margin: '0 auto',
    width: '250px',
    height: '250px',
    borderRadius: '50%',
    border: '10px solid green',
  },

  // This object defines styles for the contact form
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    margin: '0 auto',
  },

  // This object defines styles for the labels of each form input
  label: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },

  // This object defines styles for the form input elements
  input: {
    padding: '10px',
    marginBottom: '20px',
    border: '1px solid #333',
    borderRadius: '5px',
  },

  // This object defines styles for the submit button of the contact form
  button: {
    padding: '10px',
    background: '#0E9067',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  contactHeading: {
    fontSize: '3.5rem',
    fontWeight: 'bold',
    color: '#000',
    fontFamily: 'Dancing Script, cursive',
    textShadow: '2px 2px 5px rgba(0,0,0,0.5)',
  },
};

const Contact = () => {
  // Define a function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Get form data using FormData API
    const form = e.target;
    const data = new FormData(form);
    // Compose the email message body
    const message = `Name: ${data.get('first-name')} ${data.get('last-name')}\nEmail: ${data.get('email')}\n\nMessage: ${data.get('message')}`;
    // Use mailto link to open user's email client and prefill fields
    window.location.href = `mailto:filip.turcic@gmail.com?subject=Message from ${data.get('first-name')} ${data.get('last-name')}&body=${encodeURIComponent(message)}`;
  };

  // Define a function to handle form clearing
  const handleClearForm = () => {
    document.getElementById('contact-form').reset(); // Reset the form
  };

  // Render a contact section with a heading and an image
  // Then Render a form with labels and input fields for name, last name, email and message
  return (
    <section id="contact" style={styles.container}>
      <h1 style={styles.contactHeading}>Make Contact</h1>
      <img style={styles.image} src={myPic} alt="Contact Me" />
      <form id="contact-form" style={styles.form} onSubmit={handleSubmit}>
        <h2>Send me a message</h2>
        <label style={styles.label} htmlFor="first-name">
          First Name *
        </label>
        <input style={styles.input} type="text" id="first-name" name="first-name" required />
        <label style={styles.label} htmlFor="last-name">
          Last Name *
        </label>
        <input style={styles.input} type="text" id="last-name" name="last-name" required />
        <label style={styles.label} htmlFor="email">
          Email *
        </label>
        <input style={styles.input} type="email" id="email" name="email" required />
        <label style={styles.label} htmlFor="message">
          Type your message here *
        </label>
        <textarea style={styles.input} id="message" name="message" rows="5" required></textarea>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button style={styles.button} type="submit">
            Send
          </button>
          {/* Clear Form button */}
          <button style={{ ...styles.button, backgroundColor: 'red' }} type="button" onClick={handleClearForm}>
            Clear Form
          </button>
        </div>
      </form>
    </section>
  );
};

export default Contact;
