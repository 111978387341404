// Importing the twitter icon from the assets folder
import twitter from '../assets/images/twitter-64.png';
// Importing the twitter icon from the assets folder
import likedin from '../assets/images/linkedin-64.png';

// Styling for the social media icons
const styles = {
  socialIcon: {
    width: '48px',
    margin: '0 3px',
  },
}

// Functional component for the social media icons had to add rel="noreferrer" to fix warning in twitter link 
const Social = ({pos}) => {
  return (
// Creating a div with the position passed as a prop
    <div style={pos}>

      <a href="https://www.linkedin.com/in/damir-turcic-35162a287/" target="_blank"     rel="noreferrer noopener" title="Likedin">
          <img src={likedin} style={styles.socialIcon} alt='likedin' />
      </a>

      <a href="https://twitter.com/DTurcic" target="_blank"     rel="noreferrer noopener" title="Twitter">
          <img src={twitter} style={styles.socialIcon} alt='twitter' />
      </a>
    </div>
  );
};

// Exporting the Social component as the default export
export default Social;
